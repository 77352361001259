import { createSlice } from "@reduxjs/toolkit";

export const userDetailsDrawerSlice = createSlice({
  name: "toastData",
  initialState: {
    isUpdated: false,
  },
  reducers: {
    setIsUpdated: (state, action) => {
      state.isUpdated = action.payload;
    },
  },
});

export const { setIsUpdated } = userDetailsDrawerSlice.actions;

export default userDetailsDrawerSlice.reducer;
