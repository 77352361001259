import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { BrandingPage } from "../../Shared/BrandingPage";
import {
  MainHeaderText,
  BrandingPageChildContainer,
} from "../../Shared/CommonComponents";
import { RouteConstants } from "../../../constants/RouteConstants";
import { AppConstants } from "../../../constants/AppConstants";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import { signUpAPICall } from "../../../http/authentication-service";
import DialogPopup from "../../Shared/DialogPopup";
import Terms from "../Terms/terms";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { setToast } from "../../../app/toastReducer";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import Cookies from "js-cookie";
import SingUpUserForm from "../../Shared/SignupForm";
import { Helmet } from "react-helmet";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [termsDialod, setTermsDialog] = useState<boolean>(false);
  const setCookie = (name: string, value: string) => {
    Cookies.set(name, value, {
      expires: 1,
      domain: ".trestleiq.com",
      secure: true,
      sameSite: "none",
      // path: "/",
    });
  };

  useEffect(() => {
    try {
      handleMixPanel(MixPanelEvents.USER_SIGNUP, {});
    } catch (e) {
      console.log(e);
    }
  }, []);

  const FinalCall = async (payload: any, formError: any, setFormError: any) => {
    await signUpAPICall(payload).then((response: any) => {
      const { status = 401, data = {} } = response || {};
      if (status === HTTP_STATUS.HTTP_OK) {
        const { id = "", email = "", cognitoId = "" } = data.data || {};
        const verificationData = { id, email, cognitoId };
        sessionStorage.setItem("signup-data", JSON.stringify(verificationData));
        sessionStorage.setItem("signup-form", JSON.stringify(payload));
        const newDate = new Date();
        setCookie(
          "developerportal",
          `${
            payload.email
          }|signup|${newDate.getUTCHours()}:${newDate.getUTCMinutes()}:${newDate.getUTCSeconds()}`
        );
        window.dataLayer = window.dataLayer || [];  // Added GMT event trigger
        window.dataLayer.push({
          event: 'sign_up',
          method: 'portal sign up',
          userId: id
        });
        
        setIsLoading(false);
        navigate(RouteConstants.ROUTE_VERIFY);
      } else {
        const { error, details } = data || {};
        if (details === "Email is Invalid") {
          setFormError({ ...formError, email: AppConstants.EMAIL_ERROR_MSG });
        } else {
          dispatch(
            setToast({
              open: true,
              severity: "error",
              msg: details,
            })
          );
          if (details === "User with same email already exists") {
            navigate(RouteConstants.ROUTE_ALREADY_ACCOUNT);
          }
        }

        if (error === "Email is Invalid") {
          handleMixPanel(MixPanelEvents.USER_SIGNUP_INVALID_EMAIL, {
            email: payload.email,
            error: error,
          });
        }
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trestle Developer Portal - Sign Up</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DEV_PORTAL_URL}signup`}
        />
      </Helmet>
      <BrandingPage loader={isLoading}>
        <BrandingPageChildContainer>
          <MainHeaderText>{ConstantTexts.SIGNUP_HEADER}</MainHeaderText>
          <SingUpUserForm
            type={"SIGNUP"}
            SignUpFinalCall={FinalCall}
            setIsLoading={setIsLoading}
            setTermsDialog={setTermsDialog}
          />
        </BrandingPageChildContainer>
      </BrandingPage>
      <DialogPopup
        open={termsDialod}
        onClose={(val: any) => {
          setTermsDialog(val);
        }}
        closeButton={true}
        component={
          <>
            <Terms onClose={setTermsDialog} />
          </>
        }
        closeOnOut={true}
        title={"Terms of Service"}
      />
    </>
  );
};
